<script>
import { mapActions } from "vuex";

export default {
  name: 'CargarVideoExternoModal',
  data() {
    return {
      formData: {
        nombre_archivo: '',
        url_video: '',
        descripcion_archivo: '',
      },
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      id_usuario: this.$ls.get("user").id_usuario,
    };
  },
  computed: {
    isFormValid() {
      return this.formData.nombre_archivo && this.validateYoutubeUrl(this.formData.url_video);
    }
  },
  methods: {
    ...mapActions("tvCorporativaModule", ["uploadFileSubscription"]),
    
    validateYoutubeUrl(url) {
      const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
      return regex.test(url);
    },
    
    extractYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      return (match && match[2].length === 11) ? match[2] : null;
    },
    
    getEmbedUrl(videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    },
    
    async handleSubmit() {
      if (!this.isFormValid) {
        this.$toast.open({
          message: "Por favor, complete los campos requeridos.",
          type: "error",
          duration: 6000,
          position: "top-right",
        });
        return
      }
      
      this.$emit('showSpinner');
      
      try {
        const videoId = this.extractYoutubeId(this.formData.url_video);
        
        if (!videoId) {
          this.$toast.open({
            message: "URL de YouTube inválida.",
            type: "error",
            duration: 6000,
            position: "top-right",
          });
          this.$emit('hideSpinner');
          return;
        }
        
        const embedUrl = this.getEmbedUrl(videoId);
        
        const youtubeData = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          tipo_archivo: 7,
          descripcion: this.formData.descripcion_archivo,
          file:{
            name: this.formData.nombre_archivo,
            src: embedUrl,
            type: "url",
            duration: 0,
            
          }
        };

        console.log("youtubeData", youtubeData);
        
        
        const fileObservable = await this.uploadFileSubscription(youtubeData);
        
        const subscription = fileObservable.subscribe(async (data) => {
          const uploadProgressData = data.data.saveFileRepo;
          if (uploadProgressData && uploadProgressData.id_archivo) {
            this.toastSuccess("Video de YouTube agregado correctamente");
            this.clean();
            this.$emit('videoUploaded');
            this.$emit('closeModal');
            this.$emit('hideSpinner');
            subscription.unsubscribe();
          }
        });
      } catch (error) {
        console.error("Error al subir video de YouTube:", error);
        this.toastError("Error al guardar el video de YouTube");
        this.$emit('hideSpinner');
      }
    },
    clean(){
      this.formData = {
        nombre_archivo: '',
        url_video: '',
        descripcion_archivo: '',
      };

    },
    
    toastSuccess(message) {
      this.$toast.open({
        message,
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    
    toastError(message) {
      this.$toast.open({
        message,
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    }
  }
};
</script>
<template>
  <div class="modal-content add-banner__modal-container">
    <div class="modal-header">
      <h1 class="modal-title fs-5">
        <font-awesome-icon icon="video" class="color-main pe-2" />
        <span>Cargar video de YouTube</span>
      </h1>
      <button type="button" class="modal-custom-close" @click="$emit('closeModal')">
        <font-awesome-icon icon="xmark" />
      </button>
    </div>
    <div class="modal-body">
      <form @submit.prevent="handleSubmit">
        <div class="mb-3">
          <label for="nombre_archivo" class="form-label">Nombre del video *</label>
          <input type="text" class="form-control input-custom" id="nombre_archivo" v-model="formData.nombre_archivo"
            required maxlength="255" />
        </div>
        <div class="mb-3">
          <label for="url_video" class="form-label">URL del video (YouTube) *</label>
          <input type="url" class="form-control input-custom" id="url_video" v-model="formData.url_video"
            placeholder="https://www.youtube.com/watch?v=..." required />
          <small class="text-muted">Ejemplo: https://www.youtube.com/watch?v=xxxxxxxxxxx</small>
        </div>
        <div class="mb-3">
          <label for="descripcion_archivo" class="form-label">Descripción</label>
          <textarea class="form-control input-custom" maxlength="255" id="descripcion_archivo"
            v-model="formData.descripcion_archivo" rows="5"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-custom-color-white rounded-pill mw-100" @click="$emit('closeModal')">
        <i class="fa-solid fa-angle-left pe-1"></i>
        Cancelar
      </button>
      <button type="button" class="btn btn-custom-color-blue rounded-pill mw-100" @click="handleSubmit"
        :disabled="!isFormValid">
        Guardar
      </button>
    </div>
  </div>
</template>

